@tailwind base;
@tailwind components;
@tailwind utilities;



.maincontainer
{
    width: 350px;
    height: 320px;
    margin: 10px;
    float: left; 
}


/* style the maincontainer class with all child div's of class .front */
.maincontainer > .front
{
    position: absolute;
    transform: perspective(600px) rotateY(0deg);
    
    width: 302px;
    height: 290px;
    
    backface-visibility: hidden; /* cant see the backside elements as theyre turning around */
    transition: transform .5s linear 0s;
}

/* style the maincontainer class with all child div's of class .back */
.maincontainer > .back
{
    position: absolute;
    transform: perspective(600px) rotateY(180deg);
    color: #fff;
    width: 302px;
    height: 290px;
    border-radius: 10px;
    padding: 5px;
    backface-visibility: hidden; /* cant see the backside elements as theyre turning around */
    transition: transform .5s linear 0s;
}

.maincontainer:hover > .front
{
    transform: perspective(600px) rotateY(-180deg);
}

.maincontainer:hover > .back
{
    transform: perspective(600px) rotateY(0deg);
}

