/* text animation */

@keyframes wave {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes colorCycle {
  0%,
  100% {
    color: #ff0000;
  }
  25% {
    color: #3346c3;
  }
  50% {
    color: #f92727;
  }
  75% {
    color: #ff0000;
  }
}

.animate-wave {
  display: inline-block;
  animation: wave 3s infinite, colorCycle 2s infinite;
}

@keyframes wave {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

.border-container {
  animation: wave 2s infinite alternate;
  margin: 0 auto;
}

/* course slider */

.awssld__caption {
  position: absolute;
  color: white; /* Caption text color */
  padding: 5px 10px;
  border-radius: 5px;
}

/* Ensure background color for content */

/* Slider item styling */
.slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.awssld__next {
  background-color: red;
  width: 10px;
}

.awssld__bullets {
  visibility: hidden;
}

/* ---------- scroll bar ----------- */

.scrollbar-style-one::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-style-one::-webkit-scrollbar-thumb {
  background-color: #4a90e2;
  border-radius: 10px;
}

.scrollbar-style-one::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
